import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const TransitionContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "position",
})(({ position }) => ({
    position: "relative",
    width: "100%",
    ...(position === "center" && {
        minHeight: "100px",
    }),
}));

export const TransitionLoaderWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "position",
})(({ position }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: position,
    alignItems: "center",
    transition: "opacity 300ms ease-in-out",
}));

export const TransitionContentWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "position",
})(({ position }) => ({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: "opacity 300ms ease-in-out",
}));

export const LoadingText = styled("p")({
    margin: 0,
    display: "flex",
    alignItems: "center",
    "& .dot": {
        marginLeft: "1px",
        animation: "bounce 1.3s linear infinite",
        "&:nth-of-type(2)": {
            animationDelay: "0.2s",
        },
        "&:nth-of-type(3)": {
            animationDelay: "0.4s",
        },
    },
    "@keyframes bounce": {
        "0%, 60%, 100%": {
            transform: "translateY(0)",
        },
        "30%": {
            transform: "translateY(-4px)",
        },
    },
});
