export enum JobStatus {
    NOT_DEPLOYED = "not-deployed",
    CREATED = "created",
    PENDING = "pending",
    ACTIVE = "active",
    INACTIVE = "inactive",
    RUNNING = "running",
    STOPPING = "stopping",
    STOPPED = "stopped",
    FINISHED = "finished",
    FAILED = "failed",
    BUILD_DOCKER = "build-docker",
    DOCKER_FAILED = "docker-failed",
    DOCKER_FAILED_INSUFFICIENT_MEMORY = "docker-failed-insufficient-memory",
    DOCKER_FAILED_TIMEOUT = "docker-failed-timeout",
    DOCKER_BUILD_COMPLETE = "docker-build-complete",
    PROVISIONING = "provisioning",
    PROVISIONING_FAILED = "provisioning-failed",
    INFRASTRUCTURE_FAILED = "infrastructure-failed",
    WEBSERVICE_ACTIVE = "waiting-for-traffic",
    WEBSERVICE_PENDING = "deploy-service",
}

export enum JobStatusEKS {
    CREATED = "created",
    PENDING = "pending",
    RUNNING = "running",
    STOPPED = "stopped",
    STOPPING = "stopping",
    BUILD_DOCKER = "build-docker",
    DOCKER_FAILED = "docker-failed",
    DOCKER_BUILD_COMPLETE = "docker-build-complete",
    FAILED = "failed",
    UNKNOWN = "unknown",
    WEBSERVICE_PENDING = "webservice-pending",
}

export const UserType = {
    COMMUNITY: { value: "community", label: "Community" },
    PRO: { value: "pro", label: "Professional" },
    ADMIN: { value: "admin", label: "Admin" },
    TEAMS: { value: "teams", label: "Teams" },
    ENTERPRISE: { value: "enterprise", label: "Enterprise" },
};

export enum CertificateStatus {
    PENDING_VALIDATION = "PENDING_VALIDATION",
    ISSUED = "ISSUED",
    INACTIVE = "INACTIVE",
    EXPIRED = "EXPIRED",
    VALIDATION_TIMED_OUT = "VALIDATION_TIMED_OUT",
    REVOKED = "REVOKED",
    FAILED = "FAILED",
}

export enum AuthenticationType {
    PUBLIC = "PUBLIC",
    AUTH0 = "auth0",
    PASSWORD = "password",
    ENTRA = "entra",
}

export const Framework = {
    VOILA: "voila",
    DOCKER: "docker",
    STREAMLIT: "streamlit",
    PANEL: "panel",
    SOLARA: "solara",
    SHINY_R: "shiny-r",
    DASH: "dash",
    FLASK: "flask",
    CHAINLIT: "chainlit",
};
