import React from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

function CancelSubscriptionButton({ subscription, onCancelClick }) {
    if (!subscription) {
        return null;
    }

    const isSubscriptionActive = !subscription.cancel_at_period_end;

    return (
        <Button
            data-testid="cancelSubscriptionButton"
            variant="outline"
            onClick={isSubscriptionActive ? onCancelClick : undefined}
            disabled={!isSubscriptionActive}
        >
            {isSubscriptionActive
                ? "Cancel Subscription"
                : "Subscription Cancelled"}
        </Button>
    );
}
CancelSubscriptionButton.defaultProps = {
    subscription: null,
};

CancelSubscriptionButton.propTypes = {
    subscription: PropTypes.shape({
        cancel_at_period_end: PropTypes.string,
    }),
    onCancelClick: PropTypes.func.isRequired,
};

export default CancelSubscriptionButton;
