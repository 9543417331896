export const AUTH0_FEATURE_ID = "auth0_authentication";

export const AUTH0_ISSUER_BASE_URL = "AUTH_ISSUER_BASE_URL";
export const AUTH0_CLIENT_ID = "AUTH_CLIENT_ID";
export const AUTH0_CLIENT_SECRET = "AUTH_CLIENT_SECRET";
export const AUTH0_SECRET = "AUTH_SECRET";
export const AUTH0_TEMPLATE_NAME = "node-auth0";
export const AUTH0_BASE_URL = "AUTH_BASE_URL";

export const hasAllAuth0Secrets = (secrets) => {
    const NECESSARY_SECRETS_FOR_AUTH0 = [
        AUTH0_ISSUER_BASE_URL,
        AUTH0_CLIENT_ID,
        AUTH0_CLIENT_SECRET,
        AUTH0_SECRET,
    ];
    return NECESSARY_SECRETS_FOR_AUTH0.every((key) =>
        secrets.some((keyPair) => keyPair.key === key)
    );
};

export const hasCustomDomainAuth0Secret = (secrets) =>
    secrets.some((keyPair) => keyPair.key === AUTH0_BASE_URL);

function getRandomValues(length) {
    const array = new Uint8Array(length);
    return crypto.getRandomValues(array);
}

// Generate random string with specified characteristics
export function generateRandomString(
    length = 32,
    options = {
        includeUppercase: true,
        includeLowercase: true,
        includeNumbers: true,
        includeSpecial: true,
    }
) {
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const special = "!@#$%^&*()_+-=[]{}|;:,.<>?";

    let chars = "";
    if (options.includeUppercase) chars += uppercase;
    if (options.includeLowercase) chars += lowercase;
    if (options.includeNumbers) chars += numbers;
    if (options.includeSpecial) chars += special;

    if (!chars) throw new Error("At least one character type must be selected");

    let result = "";
    // Get extra bytes to handle modulo bias
    const randomBytes = getRandomValues(length * 2);
    let i = 0;

    while (result.length < length) {
        if (i >= randomBytes.length) {
            // If we run out of random bytes, get more
            const newBytes = getRandomValues(length * 2);
            randomBytes.set(newBytes);
            i = 0;
        }

        // Use modulo to select character from our character set
        const randomIndex = randomBytes[i] % chars.length;
        result += chars[randomIndex];
        i += 1;
    }

    return result;
}
