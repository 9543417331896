import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    LoadingText,
    TransitionContainer,
    TransitionContentWrapper,
    TransitionLoaderWrapper,
} from "../styles/components/TransitionContainer.Styled";

function WithLoadingContainer({ isLoading, loadingText, children, position }) {
    const [loadingOpacity, setLoadingOpacity] = useState(1);
    const [contentOpacity, setContentOpacity] = useState(0);
    const [showLoader, setShowLoader] = useState(true);
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        let contentTimeout;
        let loaderTimeout;
        let animationFrame;

        if (!isLoading) {
            setLoadingOpacity(0);
            contentTimeout = setTimeout(() => {
                setShowContent(true);
                animationFrame = requestAnimationFrame(() => {
                    setContentOpacity(1);
                });
            }, 300);
            loaderTimeout = setTimeout(() => {
                setShowLoader(false);
            }, 300);
        } else {
            setContentOpacity(0);
            contentTimeout = setTimeout(() => {
                setShowContent(false);
                animationFrame = requestAnimationFrame(() => {
                    setLoadingOpacity(1);
                });
            }, 300);
            loaderTimeout = setTimeout(() => {
                setShowLoader(true);
            }, 300);
        }

        return () => {
            clearTimeout(contentTimeout);
            clearTimeout(loaderTimeout);
            if (animationFrame) {
                cancelAnimationFrame(animationFrame);
            }
        };
    }, [isLoading]);

    return (
        <TransitionContainer position={position}>
            {showLoader && (
                <TransitionLoaderWrapper
                    position={position}
                    sx={{ opacity: loadingOpacity, display: "block" }}
                >
                    <LoadingText sx={{ justifyContent: position }}>
                        {loadingText || "Loading"}
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                    </LoadingText>
                </TransitionLoaderWrapper>
            )}
            {showContent && (
                <TransitionContentWrapper
                    sx={{ opacity: contentOpacity }}
                    position={position}
                >
                    {children}
                </TransitionContentWrapper>
            )}
        </TransitionContainer>
    );
}

WithLoadingContainer.defaultProps = {
    loadingText: "Loading",
    position: "center",
};

WithLoadingContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.element.isRequired,
    loadingText: PropTypes.string,
    position: PropTypes.oneOf(["left", "center", "right"]),
};

export default WithLoadingContainer;
