import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle, Box } from "@mui/material";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import StyledActionButton from "../../styles/components/UI/ActionButton.Styled";
import { AccountContext } from "../../features/user/Account";
import { handleStripeSubmit } from "../StripePaymentButton";
import { UserType } from "../../models/enum.ts";
import { PLOOMBER_CALENDLY } from "../../configurations/settings.ts";

function UpgradeAlert({ featureDisplayName, userType, featureId }) {
    const { updateSnackbarStatus } = useContext(AccountContext);

    const stripePaymentHandler = (e) => {
        e.preventDefault();
        handleStripeSubmit(updateSnackbarStatus, userType.value, true);
    };

    const bookCallHandler = (e) => {
        e.preventDefault();
        window.open(PLOOMBER_CALENDLY, "_blank");
    };

    return (
        <Box className="ControllerItem" feature-id={featureId}>
            <Alert
                severity="info"
                icon={<UpgradeIcon />}
                data-testid="helper-tooltip"
            >
                <AlertTitle>
                    {featureDisplayName} requires
                    {userType.value === UserType.ENTERPRISE.value
                        ? " an "
                        : " a "}
                    <strong>{userType.label}</strong> subscription.
                </AlertTitle>
                {userType.value === UserType.ENTERPRISE.value ? (
                    <StyledActionButton
                        data-testid="alert-upgrade-cta"
                        variant="linkSmall"
                        onClick={bookCallHandler}
                    >
                        Let&apos;s Talk
                    </StyledActionButton>
                ) : (
                    <StyledActionButton
                        data-testid="alert-upgrade-cta"
                        variant="linkSmall"
                        onClick={stripePaymentHandler}
                    >
                        Upgrade Now
                    </StyledActionButton>
                )}
            </Alert>
        </Box>
    );
}

UpgradeAlert.defaultProps = {
    featureId: "",
};

UpgradeAlert.propTypes = {
    featureDisplayName: PropTypes.string.isRequired,
    userType: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    }).isRequired,
    featureId: PropTypes.string,
};

export default UpgradeAlert;
