import React, { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import DeleteProjectDialog from "../../components/DeleteProjectDialog";
import { AppContext } from "../../context/AppContext";
import ploomberAPI from "../../services/ploomberAPI.ts";
import { AccountContext } from "../user/Account";
import { JobStatus } from "../../models/enum.ts";
import { ApplicationContext } from "./ApplicationProvider";
import DownloadProjectDocs from "./documentation/DownloadProjectDocs";
import GithubDeploymentDocs from "./documentation/GithubDeploymentDocs";

function determineButtonProperties(jobId, jobStatus, projectId, projectName) {
    const { navigate } = useContext(AppContext);

    let buttonText = "Pending";
    let isDisabled = true;
    let onClickAction;

    switch (jobStatus) {
        case "not-deployed":
            buttonText = "Deploy";
            isDisabled = false;
            onClickAction = () => {
                navigate(`/applications/create?referredProjectId=${projectId}`);
            };
            break;
        case "running":
            buttonText = "Stop Application";
            isDisabled = false;
            onClickAction = () => {
                ploomberAPI.stopJob(jobId);
                navigate(`/applications/${projectName || projectId}/${jobId}`);
            };
            break;
        case "stopping":
            buttonText = "Stop Application";
            isDisabled = true;
            break;
        case "stopped":
            buttonText = "Start Application";
            isDisabled = false;
            onClickAction = () => {
                ploomberAPI.startJob(jobId);
                navigate(`/applications/${projectName || projectId}/${jobId}`);
            };
            break;
        case "infrastructure-failed":
        case "provisioning-failed":
        case "docker-failed-insufficient-memory":
        case "docker-failed-timeout":
        case "docker-failed":
        case "failed":
            buttonText = "Failed";
            onClickAction = () => {};
            break;
        default:
            buttonText = "Pending";
            onClickAction = () => {};
    }

    return {
        buttonText,
        isDisabled,
        onClickAction,
    };
}

function ApplicationSettingsMenu({
    projectId,
    jobId,
    jobStatus,
    projectName,
    applicationUrl,
    canRedeploy,
    enableAnalyticsDashboard = false,
    isEKSDeployment,
    setJobStatus,
}) {
    const { navigate } = useContext(AppContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { onApplicationDeleted } = useContext(ApplicationContext);
    const { canUserAccessComponent, userType } = useContext(AccountContext);
    const [disabled, setDisabled] = useState();
    const [searchParams] = useSearchParams();
    const open = Boolean(anchorEl);
    const [openDownloadDocs, setOpenDownloadDoc] = useState(false);
    const [openGithubDeploymentDocs, setOpenGithubDeploymentDoc] =
        useState(false);

    const [openDeleteProjectDialog, setOpenDeleteProjectDialog] =
        useState(false);

    const [openDeleteEKSProjectDialog, setOpenDeleteEKSProjectDialog] =
        useState(false);

    // Get user type for analytics button
    useEffect(() => {
        const FEATURE_ID = "viewAnalyticsReport";
        if (
            jobStatus !== JobStatus.RUNNING ||
            searchParams.get("referredProjectId")
        ) {
            setDisabled(true);
        } else {
            const canAccess = canUserAccessComponent(FEATURE_ID);
            setDisabled(!canAccess);
        }
    }, [userType, jobStatus]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = (event) => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const handleApplicationDeleted = () => {
        if (onApplicationDeleted) {
            onApplicationDeleted();
        }
    };

    const { buttonText, isDisabled, onClickAction } = determineButtonProperties(
        jobId,
        jobStatus,
        projectId,
        projectName
    );

    return (
        <>
            <DownloadProjectDocs
                open={openDownloadDocs}
                setOpen={setOpenDownloadDoc}
                projectId={projectId}
            />
            <GithubDeploymentDocs
                open={openGithubDeploymentDocs}
                setOpen={setOpenGithubDeploymentDoc}
                projectId={projectId}
            />

            <IconButton
                style={{ marginRight: 10 }}
                onClick={handleClick}
                data-testid={`${projectId}-menu-icon`}
            >
                <MoreHorizIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                data-testid={`${projectId}-menu`}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                }}
            >
                {
                    // TODO: Once we migrate to EKS completly,
                    // we should replace this menu with the current menu we have
                    isEKSDeployment ? (
                        <>
                            {jobStatus === "stopped" && (
                                <MenuItem
                                    data-testid={`${projectId}-stop-start-job-menu-item`}
                                    onClick={() => {
                                        ploomberAPI.startEKSJob(jobId);
                                        navigate(
                                            `/applications/${
                                                projectName || projectId
                                            }/${jobId}`
                                        );
                                    }}
                                    disabled={false}
                                >
                                    Start Application
                                </MenuItem>
                            )}

                            {jobStatus !== "stopped" && (
                                <MenuItem
                                    data-testid={`${projectId}-stop-start-job-menu-item`}
                                    onClick={() => {
                                        ploomberAPI.stopEKSJob(jobId);
                                        setJobStatus(JobStatus.STOPPING);
                                        navigate(
                                            `/applications/${
                                                projectName || projectId
                                            }/${jobId}`
                                        );
                                    }}
                                    disabled={false}
                                >
                                    Stop Application
                                </MenuItem>
                            )}

                            <MenuItem
                                data-testid={`${projectId}-delete-project-menu-item`}
                                onClick={() => {
                                    setOpenDeleteProjectDialog(true);
                                }}
                            >
                                Delete application
                            </MenuItem>

                            {jobId !== "" && (
                                <MenuItem
                                    data-testid={`${projectId}-redeploy-project-menu-item`}
                                    disabled={!canRedeploy}
                                    onClick={() => {
                                        navigate(
                                            `/applications/create?referredProjectId=${projectId}`
                                        );
                                    }}
                                >
                                    Redeploy Application
                                </MenuItem>
                            )}

                            {jobId !== "" && (
                                <MenuItem
                                    data-testid={`${projectId}-settings-menu-item`}
                                    onClick={() => {
                                        navigate(
                                            `/applications/${
                                                projectName ?? projectId
                                            }/${jobId}/settings`
                                        );
                                    }}
                                >
                                    Settings
                                </MenuItem>
                            )}
                        </>
                    ) : (
                        <>
                            <MenuItem
                                data-testid={`${projectId}-stop-start-job-menu-item`}
                                onClick={onClickAction}
                                disabled={isDisabled}
                            >
                                {buttonText}
                            </MenuItem>

                            {jobId !== "" && (
                                <MenuItem
                                    data-testid={`${projectId}-redeploy-project-menu-item`}
                                    disabled={!canRedeploy}
                                    onClick={() => {
                                        navigate(
                                            `/applications/create?referredProjectId=${projectId}`
                                        );
                                    }}
                                >
                                    Redeploy Application
                                </MenuItem>
                            )}

                            {jobId !== "" && (
                                <MenuItem
                                    data-testid={`${projectId}-settings-menu-item`}
                                    onClick={() => {
                                        navigate(
                                            `/applications/${
                                                projectName ?? projectId
                                            }/${jobId}/settings`
                                        );
                                    }}
                                >
                                    Settings
                                </MenuItem>
                            )}

                            {enableAnalyticsDashboard && jobId !== "" && (
                                <MenuItem
                                    data-testid={`${projectId}-application-analytics-menu-item`}
                                    disabled={disabled}
                                    onClick={() => {
                                        window.open(
                                            `${applicationUrl}/analytics-report`,
                                            "_blank"
                                        );
                                    }}
                                >
                                    Analytics Report
                                </MenuItem>
                            )}

                            <MenuItem
                                data-testid={`${projectId}-delete-project-menu-item`}
                                onClick={(event) => {
                                    handleClose(event);
                                    setOpenDeleteProjectDialog(true);
                                }}
                            >
                                Delete Application
                            </MenuItem>

                            <Divider />

                            <MenuItem
                                data-testid={`${projectId}-how-to-download`}
                                onClick={() => {
                                    setOpenDownloadDoc(true);
                                }}
                            >
                                Download this project
                            </MenuItem>
                            <MenuItem
                                data-testid={`${projectId}-how-to-github-ci`}
                                onClick={() => {
                                    setOpenGithubDeploymentDoc(true);
                                }}
                            >
                                GitHub deployment
                            </MenuItem>
                        </>
                    )
                }
            </Menu>

            <DeleteProjectDialog
                projectName={projectName}
                projectId={projectId}
                open={openDeleteProjectDialog}
                onClose={(e) => {
                    e.stopPropagation();
                    setOpenDeleteProjectDialog(false);
                }}
                onProjectDeleteComplete={handleApplicationDeleted}
                isEKSDeployment={isEKSDeployment}
            />
        </>
    );
}

ApplicationSettingsMenu.propTypes = {
    projectId: PropTypes.string.isRequired,
    jobId: PropTypes.string.isRequired,
    jobStatus: PropTypes.string.isRequired,
    projectName: PropTypes.string,
    applicationUrl: PropTypes.string.isRequired,
    enableAnalyticsDashboard: PropTypes.bool,
    canRedeploy: PropTypes.bool.isRequired,
    isEKSDeployment: PropTypes.bool,
    setJobStatus: PropTypes.func.isRequired,
};

ApplicationSettingsMenu.defaultProps = {
    projectName: null,
    enableAnalyticsDashboard: false,
    isEKSDeployment: false,
};

export default ApplicationSettingsMenu;
