import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Block, BlockContent, BlockHeader } from "../../components/Block";
import WithLoadingContainer from "../../components/WithLoadingContainer";
import { ApplicationSettingsContext } from "./ApplicationSettingsContext";
import SetCustomDomainController from "./controllers/customDomainConroller/SetCustomDomainController";
import SetProjectLabelsController from "./controllers/SetProjectLabelsController";
import Auth0URLSetupInstruction from "./controllers/AuthenticationController/Auth0/Auth0UrlSetupInstruction";
import StyledAuthenticationContainer from "../../styles/features/applications/AuthenticationContainer.Styled";

function ApplicationSettings() {
    const {
        handleUpdateProjectLabels,
        setJobId,
        job,
        isLoading,
        auth0IsEnabled,
        auth0ProjectUrl,
        auth0showCustomDomainWarning,
        auth0WarningUrl,
    } = useContext(ApplicationSettingsContext);

    const { jobId } = useParams();

    useEffect(() => {
        // Initialize the Settings Context
        setJobId(jobId);
    }, []);

    return (
        <div className="ApplicationSettings page">
            <WithLoadingContainer
                isLoading={isLoading}
                loadingText="Loading Application Settings"
            >
                <Block id="overview" open={Boolean(true)}>
                    <BlockHeader
                        expandable={false}
                        title="Overview"
                        description=""
                    />
                    <BlockContent>
                        <SetProjectLabelsController
                            labels={job.labels}
                            onChange={(newLabels) => {
                                handleUpdateProjectLabels(newLabels);
                            }}
                            disabled={isLoading}
                        />
                    </BlockContent>
                </Block>
                <Block id="customDomainSettings" open={Boolean(true)}>
                    <BlockHeader
                        expandable={false}
                        title="Custom domains"
                        description="Use your domain to host this application"
                    />
                    <BlockContent>
                        <SetCustomDomainController />
                        {auth0IsEnabled && (
                            <StyledAuthenticationContainer
                                style={{ border: "none", padding: "0rem" }}
                            >
                                <Auth0URLSetupInstruction
                                    projectUrl={auth0ProjectUrl}
                                    showCustomDomainWarning={
                                        auth0showCustomDomainWarning
                                    }
                                    CustomDomainWarningUrl={auth0WarningUrl}
                                />
                            </StyledAuthenticationContainer>
                        )}
                    </BlockContent>
                </Block>
            </WithLoadingContainer>
        </div>
    );
}

ApplicationSettings.propTypes = {};

ApplicationSettings.defaultProps = {};

export default ApplicationSettings;
