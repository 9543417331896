import { styled } from "@mui/material/styles";

const StyledAuthenticationContainer = styled("div")`
    margin-top: 15px;
    padding: 1rem;
    borderradius: 0.2rem;
    border: 1px solid #c2c8d0;

    & .container-password {
        padding-top: 1rem;
        gap: 50px;
        width: 100%;
        display: inline-flex;
    }

    & .container-auth0 {
        gap: 50px;
        width: 100%;
        display: inline-flex;

        & .left {
            flex: 1;
        }
        & .right {
            flex: 2;
        }
    }

    & .MuiInputLabel-root {
        -webkit-transform: inherit;
        top: -20px;
    }
`;

export default StyledAuthenticationContainer;
