import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const StyledActionButton = styled(Button)(({ theme }) => ({
    "&.MuiButton-sizeMedium": {
        fontSize: "0.8125rem",
        padding: "3px 9px",
    },

    "&.MuiButton-sizeSmall": {
        fontSize: "0.75rem",
        padding: "3px 9px",
    },

    "&.cancelBtn": {
        textTransform: "none",
        fontSize: "0.875rem",
    },

    "&.MuiButton-link": {
        fontSize: "1rem",
        textDecoration: "underline",
        padding: 0,
        textTransform: "none",
        "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
        },
    },

    "&.MuiButton-linkSmall": {
        fontSize: "0.75rem",
        textDecoration: "underline",
        padding: 0,
        textTransform: "none",
        "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
        },
    },
}));

export default StyledActionButton;
