import { styled } from "@mui/material/styles";

const StyledAuthenticationFeature = styled("div")(() => ({
    "& .MuiAlert-root .MuiSvgIcon-root": {
        fontSize: "18px",
    },

    "& .MuiFormControl-root": {
        width: "100%",
        "& label": {
            fontWeight: "600",
            color: "#000",
            marginBottom: "5px",
        },
    },

    "& .BlockTable": {
        padding: 0,
    },

    "& .toggleButtonContainer": {
        display: "inline-flex",
        gap: "25px",
        width: "100%",
    },
}));

export default StyledAuthenticationFeature;
