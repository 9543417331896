import React, { useState, useEffect } from "react";
import { FormControl } from "@mui/material";
import PropTypes from "prop-types";
import { BlockTable } from "../../../../components/BlockTable";
import BlockToggleButton from "../../../../components/Block/BlockToggleButton";
import PasswordController from "./PasswordController";
import Auth0Controller from "./Auth0/Auth0Controller";
import { AUTH0_TEMPLATE_NAME } from "./Auth0/Auth0Utils";
import EntraController from "./EntraController";
import { AuthenticationType } from "../../../../models/enum.ts";
import StyledAuthenticationFeature from "../../../../styles/features/applications/controllers/AuthenticationFeature.Styled";

const FEATURE_ID = "authentication";

function AuthenticationController({
    // Common
    className,
    previousTypeOfCredentials,
    // Password
    onPasswordCredentialsUpdate,
    // Auth0
    secrets,
    onSecretsChange,
    onRemoveKey,
    setSecurityTemplate,
    setAuth0CredentialsValid,
    projectUrl,
}) {
    const [authType, setAuthType] = useState(
        AuthenticationType.AUTH_TYPE_PUBLIC
    );

    // New component logic end
    useEffect(() => {
        if (previousTypeOfCredentials === AuthenticationType.AUTH0) {
            setSecurityTemplate(AUTH0_TEMPLATE_NAME);
        } else {
            setSecurityTemplate(null);
        }
        setAuthType(previousTypeOfCredentials);
    }, [previousTypeOfCredentials]);

    return (
        <StyledAuthenticationFeature feature-id={FEATURE_ID}>
            <div className="label">Authentication</div>
            <FormControl className={className} data-testid="set-auth">
                <div>
                    <BlockTable
                        keys={[
                            {
                                style: {
                                    padding: 0,
                                },
                            },
                        ]}
                        values={[
                            [
                                <div className="toggleButtonContainer">
                                    <BlockToggleButton
                                        title="Public Application"
                                        id="PublicApplication"
                                        onClick={() => {
                                            setAuthType(
                                                AuthenticationType.PUBLIC
                                            );
                                            setSecurityTemplate(null);
                                        }}
                                        checked={
                                            authType ===
                                            AuthenticationType.PUBLIC
                                        }
                                    />
                                    <BlockToggleButton
                                        title="Password Protected"
                                        id="toggle-password-protection"
                                        onClick={() => {
                                            setAuthType(
                                                AuthenticationType.PASSWORD
                                            );
                                            setSecurityTemplate(null);
                                        }}
                                        checked={
                                            authType ===
                                            AuthenticationType.PASSWORD
                                        }
                                    />
                                    <BlockToggleButton
                                        title="Auth0 Integration"
                                        id="toggle-auth0-protection"
                                        onClick={() => {
                                            setAuthType(
                                                AuthenticationType.AUTH0
                                            );
                                            setSecurityTemplate(
                                                AUTH0_TEMPLATE_NAME
                                            );
                                        }}
                                        checked={
                                            authType ===
                                            AuthenticationType.AUTH0
                                        }
                                    />
                                    <BlockToggleButton
                                        title="Microsoft EntraID"
                                        id="toggle-entra-protection"
                                        onClick={() => {
                                            setAuthType(
                                                AuthenticationType.ENTRA
                                            );
                                            setSecurityTemplate(null);
                                        }}
                                        checked={
                                            authType ===
                                            AuthenticationType.ENTRA
                                        }
                                    />
                                </div>,
                            ],
                        ]}
                    />
                </div>

                <PasswordController
                    isEnabled={authType === AuthenticationType.PASSWORD}
                    onCredentialsUpdate={onPasswordCredentialsUpdate}
                    hadPreviousCredentials={
                        previousTypeOfCredentials ===
                        AuthenticationType.PASSWORD
                    }
                />

                <Auth0Controller
                    isEnabled={authType === AuthenticationType.AUTH0}
                    onCredentialsUpdate={onPasswordCredentialsUpdate}
                    hadAuth0OnPreviousDeployment={
                        previousTypeOfCredentials === AuthenticationType.AUTH0
                    }
                    secrets={secrets}
                    projectUrl={projectUrl}
                    onSecretsChange={onSecretsChange}
                    onRemoveKey={onRemoveKey}
                    setAuthCredentialsValid={setAuth0CredentialsValid}
                />

                <EntraController
                    authEnabled={authType === AuthenticationType.ENTRA}
                    onCredentialsUpdate={onPasswordCredentialsUpdate}
                    hadPreviousCredentials={
                        previousTypeOfCredentials === AuthenticationType.ENTRA
                    }
                    secrets={secrets}
                    onSecretsChange={onSecretsChange}
                />
            </FormControl>
        </StyledAuthenticationFeature>
    );
}

AuthenticationController.defaultProps = {
    className: "",
    projectUrl: "",
    previousTypeOfCredentials: AuthenticationType.PUBLIC,
};

AuthenticationController.propTypes = {
    // Commun
    className: PropTypes.string,
    previousTypeOfCredentials: PropTypes.oneOf([
        AuthenticationType.PUBLIC,
        AuthenticationType.AUTH0,
        AuthenticationType.PASSWORD,
        AuthenticationType.ENTRA,
    ]),
    // Password base
    onPasswordCredentialsUpdate: PropTypes.func.isRequired,
    // Auth0
    secrets: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
    onSecretsChange: PropTypes.func.isRequired,
    onRemoveKey: PropTypes.func.isRequired,
    setSecurityTemplate: PropTypes.func.isRequired,
    setAuth0CredentialsValid: PropTypes.func.isRequired,
    projectUrl: PropTypes.string,
};

export default AuthenticationController;
