import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import PropTypes from "prop-types";
import ploomberAPI from "../../services/ploomberAPI.ts";
import { checkIfEKSDeployment, parseErrorMessage } from "../../utils/utils.ts";
import { AppContext } from "../../context/AppContext";
import {
    AUTH0_TEMPLATE_NAME,
    hasCustomDomainAuth0Secret,
} from "./controllers/AuthenticationController/Auth0/Auth0Utils";
import { AccountContext } from "../user/Account";

export const CUSTOM_DOMAIN_FEATURE_ID = "customDomain";

const ApplicationSettingsContext = createContext();

function ApplicationSettingsProvider({ children }) {
    // Project / Job info
    const [projectId, setProjectId] = useState(null);
    const [jobId, setJobId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [job, setJob] = useState({ labels: [] });
    const [jobInfo, setJobInfo] = useState({});
    const [project, setProject] = useState({});

    // Custom Domain
    const [isDomainSettingsDisable, setIsDomainSettingsDisable] =
        useState(true);
    const [domainSettingsDisabledReason, setDomainSettingsDisabledReason] =
        useState("");
    const [userRegisteredDomains, setUserRegisteredDomains] = useState([]);

    // Auth0
    const [auth0ProjectUrl, setAuth0ProjectUrl] = useState("");
    const [auth0IsEnabled, setAuth0IsEnabled] = useState(false);
    const [auth0showCustomDomainWarning, setAuth0ShowCustomDomainWarning] =
        useState(false);
    const [auth0WarningUrl, setAuth0WarningUrl] = useState("");

    const { updateSnackbarStatus } = useContext(AppContext);
    const { canUserAccessComponent } = useContext(AccountContext);

    // Loader Helper -----
    const initDomainSettings = (fetchedJob, fetchedCustomDomains) => {
        if (fetchedCustomDomains === null) return; // User doesn't have access
        if (checkIfEKSDeployment(fetchedJob)) {
            setIsDomainSettingsDisable(false);
        } else {
            setIsDomainSettingsDisable(fetchedJob.status !== "running");
            setDomainSettingsDisabledReason(
                "This settings is only available when your app is running"
            );
        }
        setUserRegisteredDomains(fetchedCustomDomains);
    };

    const initAuth0Settings = (fetchedProject, fetchedJobInfo) => {
        const lastJob = fetchedProject.jobs?.at(-1);
        if (lastJob?.template !== AUTH0_TEMPLATE_NAME) {
            return;
        }

        setAuth0IsEnabled(true);

        // Handle custom domain configuration
        const secrets =
            fetchedProject.secrets?.map((secret) => ({ key: secret })) ?? [];

        // Determine and set the project URL
        if (hasCustomDomainAuth0Secret(secrets)) {
            setAuth0ProjectUrl(fetchedProject.url);
            setAuth0ShowCustomDomainWarning(false);
            return;
        }

        // Find default Auth0 URL from app links
        const appIdLink = fetchedJobInfo.appLinks
            ?.map((el) => el.id)
            .find((el) => el.includes(fetchedProject.id));
        setAuth0ProjectUrl(`https://${appIdLink}`);

        // We only have the app-id.ploomber.app
        if (fetchedJobInfo.appLinks.length === 1) {
            setAuth0ShowCustomDomainWarning(false);
            return;
        }

        // We do not only have app-id.ploomber.app & the AUTH0 URL is missing
        const customLink = fetchedJobInfo.appLinks
            ?.map((el) => el.id)
            .find((el) => !el.includes(fetchedProject.id));
        if (customLink) {
            setAuth0ShowCustomDomainWarning(true);
            setAuth0WarningUrl(customLink);
        }
    };

    const initalFetch = async (fetchedJob) => {
        const shouldFetchDomain = canUserAccessComponent(
            CUSTOM_DOMAIN_FEATURE_ID
        );
        let [fetchedProject, fetchedJobInfo, fetchedCustomDomains] = [
            null,
            null,
            null,
        ];
        if (shouldFetchDomain) {
            [fetchedProject, fetchedJobInfo, fetchedCustomDomains] =
                await Promise.all([
                    ploomberAPI.getUserProject(fetchedJob.projectId),
                    ploomberAPI.getJobInfo(jobId),
                    ploomberAPI.getCustomDomains(),
                ]);
        } else {
            [fetchedProject, fetchedJobInfo] = await Promise.all([
                ploomberAPI.getUserProject(fetchedJob.projectId),
                ploomberAPI.getJobInfo(jobId),
            ]);
        }
        return [fetchedProject, fetchedJobInfo, fetchedCustomDomains];
    };

    // Loader -----------
    useEffect(() => {
        const initSettings = async () => {
            if (jobId === null) return;
            try {
                setIsLoading(true);

                const fetchedJob = await ploomberAPI.getJob(jobId);
                setProjectId(fetchedJob.projectId);

                const [fetchedProject, fetchedJobInfo, fetchedCustomDomains] =
                    await initalFetch(fetchedJob);

                initDomainSettings(fetchedJob, fetchedCustomDomains);
                initAuth0Settings(fetchedProject, fetchedJobInfo);

                // Propagate state
                setJob(fetchedJob);
                setJobInfo(fetchedJobInfo);
                setProject(fetchedProject);
            } catch (err) {
                updateSnackbarStatus({
                    severity: "error",
                    message: parseErrorMessage(err),
                });
            } finally {
                // Show to user the settings
                setIsLoading(false);
            }
        };

        initSettings();
    }, [jobId]);

    // Handler ----------
    const handleUpdateProjectLabels = async (newLabels) => {
        try {
            if (projectId === null) {
                throw new Error(
                    "Please select a project before updating labels"
                );
            }

            await ploomberAPI.updateProjectLabels(projectId, newLabels);
            updateSnackbarStatus({
                message: `Labels updated successfully`,
            });

            setJob((prev) => ({ ...prev, labels: newLabels }));
        } catch (error) {
            updateSnackbarStatus({
                message: `Failed to update labels: ${error}`,
                severity: "error",
            });
        }
    };
    const providerValue = useMemo(
        () => ({
            // Should be set when the settings load
            setJobId,
            // Update with Side Effect
            handleUpdateProjectLabels,
            // Data
            projectId,
            isDomainSettingsDisable,
            jobId,
            job,
            jobInfo,
            project,
            isLoading,
            domainSettingsDisabledReason,
            auth0IsEnabled,
            auth0ProjectUrl,
            auth0showCustomDomainWarning,
            auth0WarningUrl,
            userRegisteredDomains,
        }),
        [
            handleUpdateProjectLabels,
            projectId,
            jobId,
            isDomainSettingsDisable,
            job,
            jobInfo,
            project,
            isLoading,
            domainSettingsDisabledReason,
            auth0IsEnabled,
            auth0ProjectUrl,
            auth0showCustomDomainWarning,
            auth0WarningUrl,
            userRegisteredDomains,
        ]
    );
    return (
        <ApplicationSettingsContext.Provider value={providerValue}>
            {children}
        </ApplicationSettingsContext.Provider>
    );
}

ApplicationSettingsProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired,
};

export { ApplicationSettingsContext, ApplicationSettingsProvider };
