import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Input({
    id,
    value,
    placeholder,
    disabled,
    onChange,
    onBlur,
    error,
    className,
    inputRef,
    type = "text",
    showPasswordToggle = false,
    disableAutocomplete = false,
}) {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const getInputType = (hasToggle, isVisible, defaultType) => {
        if (!hasToggle) {
            return defaultType;
        }
        return isVisible ? "text" : "password";
    };

    return (
        <TextField
            inputRef={inputRef}
            className={className}
            id={id}
            variant="outlined"
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            fullWidth
            onBlur={onBlur}
            error={error}
            {...(disableAutocomplete && { autoComplete: "new-password" })}
            type={getInputType(showPasswordToggle, showPassword, type)}
            InputProps={
                showPasswordToggle
                    ? {
                          endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                      disabled={disabled}
                                  >
                                      {showPassword ? (
                                          <VisibilityOff />
                                      ) : (
                                          <Visibility />
                                      )}
                                  </IconButton>
                              </InputAdornment>
                          ),
                      }
                    : undefined
            }
        />
    );
}

Input.defaultProps = {
    value: "",
    placeholder: "",
    disabled: false,
    id: "",
    onChange: () => {},
    onBlur: () => {},
    error: false,
    className: "",
    inputRef: null,
    type: "text",
    showPasswordToggle: false,
    disableAutocomplete: false,
};

Input.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    className: PropTypes.string,
    inputRef: PropTypes.oneOfType([PropTypes.any]),
    type: PropTypes.string,
    showPasswordToggle: PropTypes.bool,
    disableAutocomplete: PropTypes.bool,
};

export default Input;
