import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SyncIcon from "@mui/icons-material/Sync";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@mui/material";
import KeyValWrapper from "../../../../../styles/components/KeyValWrapper.Styled";
import Input from "../../../../../components/UI/Form/inputs/Input";
import { AUTH0_ISSUER_BASE_URL, generateRandomString } from "./Auth0Utils";
import HelperTooltip from "../../../../../components/HelperTooltip";

function PreDefineSecret({
    index,
    mkey,
    toolTipText,
    mvalue,
    isPrevValue,
    onUpdate,
    onRemoveKey,
    setHasValidationError,
    allowGenerateRandomValue,
    isPasswordInput,
}) {
    const [value, setValue] = useState(mvalue);
    const [error, setError] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        setValue(mvalue);
    }, [mkey, mvalue, isPrevValue]);

    const validateUrl = (url) => {
        if (mkey === AUTH0_ISSUER_BASE_URL && !url.startsWith("https://")) {
            return "URL must start with https://";
        }
        return "";
    };

    const handleUpdate = () => {
        if (value && value.length > 0) {
            const validationError = validateUrl(value);
            if (validationError) {
                setHasValidationError(true);
                setError(validationError);
                return;
            }
            setHasValidationError(false);
            setError("");
            onUpdate(value);
        }
    };

    const handleGenerateRandom = () => {
        const randomValue = generateRandomString(32);
        setValue(randomValue);
        onUpdate(randomValue);
        inputRef.current?.focus();
    };

    return (
        <KeyValWrapper key={index}>
            <div style={{ flex: 1 }}>
                <div style={{ marginBottom: 5 }}>
                    <div
                        style={{ fontSize: "0.60rem", display: "inline-flex" }}
                        className="label"
                    >
                        {mkey}{" "}
                        {toolTipText && <HelperTooltip text={toolTipText} />}
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <Input
                        inputRef={inputRef}
                        style={{ flex: 1 }}
                        onBlur={handleUpdate}
                        id={`Secret-Input-${mkey}`}
                        value={isPrevValue ? "●●●●●●" : value}
                        type={isPasswordInput ? "password" : "text"}
                        showPasswordToggle={isPasswordInput}
                        disableAutocomplete
                        placeholder={
                            isPrevValue ? "●●●●●●" : "Enter Your Value"
                        }
                        onChange={(e) => {
                            setValue(e.target.value);
                            setError(validateUrl(e.target.value));
                        }}
                        disabled={isPrevValue}
                        error={!!error}
                    />
                    {!isPrevValue && allowGenerateRandomValue && (
                        <Tooltip title="Regenerate Secret Key" placement="top">
                            <SyncIcon
                                onClick={handleGenerateRandom}
                                data-testid={`auth0GenerateEnvVar${mkey}`}
                                style={{
                                    marginRight: 15,
                                    marginLeft: 15,
                                    marginTop: 5,
                                    fontSize: 25,
                                    cursor: "pointer",
                                }}
                            />
                        </Tooltip>
                    )}
                </div>
                {error && (
                    <p
                        style={{
                            color: "#d32f2f",
                            fontSize: "0.6rem",
                            marginLeft: "4px",
                        }}
                    >
                        {error}
                    </p>
                )}
            </div>
            {isPrevValue && (
                <EditIcon
                    onClick={() => {
                        onRemoveKey(mkey);
                    }}
                    data-testid={`auth0RemoveEnvVar${mkey}`}
                    style={{
                        marginRight: 25,
                        marginTop: 20,
                        fontSize: 25,
                        cursor: "pointer",
                    }}
                />
            )}
        </KeyValWrapper>
    );
}

PreDefineSecret.defaultProps = {
    allowGenerateRandomValue: false,
    isPasswordInput: false,
    toolTipText: "",
};

PreDefineSecret.propTypes = {
    mkey: PropTypes.string.isRequired,
    mvalue: PropTypes.string.isRequired,
    toolTipText: PropTypes.string,
    index: PropTypes.number.isRequired,
    isPrevValue: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onRemoveKey: PropTypes.func.isRequired,
    setHasValidationError: PropTypes.func.isRequired,
    allowGenerateRandomValue: PropTypes.bool,
    isPasswordInput: PropTypes.bool,
};

export default PreDefineSecret;
