import React from "react";
import PropTypes from "prop-types";
import UserValidation from "../../features/applications/hoc/userValidation";

function ControllerLabel({ text }) {
    return <div className="label">{text}</div>;
}

ControllerLabel.propTypes = {
    text: PropTypes.string.isRequired,
};

export default UserValidation(ControllerLabel);
