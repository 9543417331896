import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AccountContext } from "../../../user/Account";
import UpgradeAlert from "../../../../components/Pricings/UpgradeAlert";
import { UserType } from "../../../../models/enum.ts";

const FEATURE_ID = "entra_authentication";

function EntraController({
    authEnabled,
    onCredentialsUpdate,
    hadPreviousCredentials,
}) {
    const { canUserAccessComponent, userType } = useContext(AccountContext);
    const [userHasAccess, setUserHasAccess] = useState(false);

    useEffect(() => {
        setUserHasAccess(canUserAccessComponent(FEATURE_ID));
    }, [userType]);

    if (!authEnabled) {
        return null;
    }

    if (!userHasAccess) {
        return (
            <UpgradeAlert
                featureDisplayName="Microsoft Entra Integration"
                userType={UserType.ENTERPRISE}
                featureId={FEATURE_ID}
            />
        );
    }

    return <div> Enterprise Plan Only </div>;
}

EntraController.defaultProps = {
    authEnabled: false,
    hadPreviousCredentials: false,
    onCredentialsUpdate: () => {},
};

EntraController.propTypes = {
    authEnabled: PropTypes.bool,
    onCredentialsUpdate: PropTypes.func,
    hadPreviousCredentials: PropTypes.bool,
};

export default EntraController;
