import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { ReactComponent as Auth0Logo } from "../../assets/auth0-logo.svg";
import { ActionButton } from "../../components/UI";
import SetProjectLabelsController from "./controllers/SetProjectLabelsController";
import ApplicationSettingsMenu from "./ApplicationSettingsMenu";
import HelperTooltip from "../../components/HelperTooltip";
import telemetry from "../../services/telemetry.ts";
import StyledJobSummary from "../../styles/features/applications/JobSummary.Styled";
import voila from "../../assets/frameworks/v2/voila.png";
import streamlit from "../../assets/frameworks/v2/streamlit.png";
import shinyR from "../../assets/frameworks/v2/shiny-r.png";
import docker from "../../assets/frameworks/v2/docker.png";
import panel from "../../assets/frameworks/v2/panel.png";
import solara from "../../assets/frameworks/v2/solara.png";
import dash from "../../assets/frameworks/v2/dash.png";
import flask from "../../assets/frameworks/v2/flask.png";
import chainlit from "../../assets/frameworks/v2/chainlit.png";
import { AccountContext } from "../user/Account";
import {
    checkIfEKSDeployment,
    dispatchComponentReadyEvent,
} from "../../utils/utils.ts";
import ApplicationLinksController from "./ApplicationLinksController";
import { RestrictedController } from "./hoc/userValidation";
import StyledChip from "../../styles/components/Chip.Styled";
import {
    getEKSJobStatusChipColor,
    getEKSJobStatusMapping,
    isTransitioningJobStatus,
} from "../../utils/uiUtils.ts";
import { JobStatus } from "../../models/enum.ts";

const options = [
    { id: "voila", label: "Voila", image: voila },
    { id: "docker", label: "Docker", image: docker },
    { id: "panel", label: "Panel", image: panel },
    { id: "solara", label: "Solara", image: solara },
    { id: "streamlit", label: "Streamlit", image: streamlit },
    { id: "shiny-r", label: "Shiny (R)", image: shinyR },
    { id: "dash", label: "Dash", image: dash },
    { id: "flask", label: "Flask", image: flask },
    { id: "chainlit", label: "Chainlit", image: chainlit },
];

function OpenApplicationIcon(isAuth0Protected, isPasswordProtected) {
    if (isAuth0Protected) {
        return (
            <Auth0Logo style={{ height: "18px" }} data-testid="auth0-icon" />
        );
    }
    if (isPasswordProtected) {
        <LockIcon />;
    }
    return null;
}

function OpenApplicationToolTipText(isAuth0Protected, isPasswordProtected) {
    if (isAuth0Protected) {
        return (
            <div data-testid="auth0-protected-tooltip">
                This application is protected with Auth0.
            </div>
        );
    }
    if (isPasswordProtected) {
        return (
            <div data-testid="password-protected-tooltip">
                This application is protected with password.
            </div>
        );
    }
    return "Click here to open the application";
}

function JobSummary({
    status,
    job,
    jobInfo,
    isUrlUp,
    isApplicationProtected: isPasswordProtected,
    isAnalyticsProtected,
    isAuth0Protected,
    isAuth0MissingBaseUrlSecret,
    setJobStatus,
}) {
    const toRender = status && job && jobInfo;
    const disableLabelsEdit = true;
    const jobLabels = job.labels;
    const { canUserAccessComponent } = useContext(AccountContext);
    const [searchParams] = useSearchParams();
    const FEATURE_ID_ANALYTICS = "viewAnalyticsReport";
    const [appStopping, setAppStopping] = useState(false);

    function validateAnalyticsAccess() {
        if (searchParams.get("referredProjectId")) {
            return false;
        }
        return canUserAccessComponent(FEATURE_ID_ANALYTICS);
    }

    const frameworkImage = options.find((o) => o.id === jobInfo?.type)?.image;

    useEffect(() => {
        dispatchComponentReadyEvent();
    }, []);

    // Add a check to prevent the stopping status being overridden from the status
    // from backend unless it's Stopped
    useEffect(() => {
        if (status === JobStatus.STOPPING) {
            setAppStopping(true);
        }
        if (status === JobStatus.STOPPED) {
            setAppStopping(false);
        }
    }, [status]);

    const isBigScreen = useMediaQuery("(min-width: 1024px)");

    const statusToDisplay = appStopping === true ? JobStatus.STOPPING : status;

    const labelsController = (
        <SetProjectLabelsController
            labels={jobLabels}
            hideLabel
            disabled={disableLabelsEdit}
        />
    );

    /**
     * Updates the URL for Auth0 authentication, handling custom domain scenarios.
     * @param {string} url - The original URL to potentially modify
     * @returns {string} The updated URL, either using a custom domain or the original URL
     * @description Checks if the application is Auth0 protected with a custom domain and
     * the Auth0 base url isn't configure, if so, default to the app-id url if it's activated
     */
    const updateUrlForAuth0 = (url) => {
        if (isAuth0Protected && isAuth0MissingBaseUrlSecret) {
            const appIdLink = jobInfo.appLinks.find(
                (el) => el.id.includes(jobInfo.project_id) && el.isActive
            );
            if (appIdLink) {
                return `https://${appIdLink.id}`;
            }
        }
        return url;
    };

    const auth0UrlForTesting = updateUrlForAuth0("");

    const onOpenApplicationClick = (e) => {
        let url = job?.resources?.webservice;
        url = updateUrlForAuth0(url);
        telemetry.log(
            `${telemetry.Pages.ApplicationStatus}-OpenApplicationClick`,
            {
                metadata: {
                    job: JSON.stringify(job),
                    projectId: jobInfo.project_id,
                },
            }
        );
        window.open(`${url}`);
    };

    return (
        toRender && (
            <StyledJobSummary className="Top" data-testid="job-status-summary">
                <Box className="JobInfoContainer">
                    <Box className="FrameworkLabelContainer">
                        <Box className="Framework">
                            <img
                                className="FrameworkImage"
                                data-testid="framework-image"
                                src={frameworkImage}
                                alt={jobInfo.type}
                            />

                            {checkIfEKSDeployment(jobInfo) && (
                                <StyledChip
                                    data-testid="job-status-chip"
                                    label={getEKSJobStatusMapping(
                                        statusToDisplay
                                    )}
                                    color={getEKSJobStatusChipColor(
                                        statusToDisplay
                                    )}
                                    size="small"
                                    icon={
                                        isTransitioningJobStatus(
                                            statusToDisplay
                                        ) ? (
                                            <CircularProgress />
                                        ) : null
                                    }
                                />
                            )}
                        </Box>
                    </Box>
                    <Box
                        className="HardWareSettings"
                        data-testid="hardware-settings"
                    >
                        <p>
                            {jobInfo.cpu} CPU - {jobInfo.ram} RAM -{" "}
                            {!jobInfo.gpu || Number(jobInfo.gpu) === 0
                                ? "No"
                                : `${jobInfo.gpu}`}{" "}
                            GPU -
                            {!jobInfo.storage || Number(jobInfo.storage) === 0
                                ? " No"
                                : ` ${jobInfo.storage} GB `}{" "}
                            {isBigScreen && jobLabels && jobLabels.length > 0
                                ? "Storage - "
                                : "Storage"}
                            {isBigScreen ? (
                                <span style={{ display: "inline-flex" }}>
                                    {labelsController}
                                </span>
                            ) : (
                                <div>{labelsController}</div>
                            )}
                        </p>
                    </Box>
                </Box>

                <Box className="ActionsContainer">
                    <ApplicationSettingsMenu
                        projectId={jobInfo.project_id}
                        jobId={jobInfo.id}
                        jobStatus={status}
                        applicationUrl={job?.resources?.webservice}
                        projectName={job.projectName}
                        canRedeploy={job.can_redeploy}
                        isEKSDeployment={checkIfEKSDeployment(jobInfo)}
                        setJobStatus={setJobStatus}
                    />

                    <RestrictedController
                        validationFunction={() => validateAnalyticsAccess()}
                        featureId={FEATURE_ID_ANALYTICS}
                    >
                        <ActionButton
                            variant="outlined"
                            data-testid="application-analytics-button"
                            disabled={!isUrlUp}
                            onClick={() =>
                                window.open(
                                    `${job?.resources?.webservice}/analytics-report`,
                                    "_blank"
                                )
                            }
                            startIcon={
                                isAnalyticsProtected ? <LockIcon /> : undefined
                            }
                        >
                            Analytics Report
                        </ActionButton>
                    </RestrictedController>
                    <HelperTooltip
                        text={OpenApplicationToolTipText(
                            isAuth0Protected,
                            isPasswordProtected
                        )}
                    >
                        <ActionButton
                            testid={`view-application-button${auth0UrlForTesting}`}
                            variant="contained"
                            color="primary"
                            onClick={onOpenApplicationClick}
                            disabled={!isUrlUp}
                            startIcon={OpenApplicationIcon(
                                isAuth0Protected,
                                isPasswordProtected
                            )}
                        >
                            View application
                        </ActionButton>
                    </HelperTooltip>

                    <ApplicationLinksController
                        defaultUrl={job?.resources?.webservice}
                        isEKSDeployment={checkIfEKSDeployment(jobInfo)}
                        isAuth0MissingBaseUrlSecret={
                            isAuth0MissingBaseUrlSecret
                        }
                    />
                </Box>
            </StyledJobSummary>
        )
    );
}

JobSummary.propTypes = {
    status: PropTypes.string.isRequired,
    job: PropTypes.shape({
        projectName: PropTypes.string,
        summary: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
        status: PropTypes.string.isRequired,
        resources: PropTypes.shape({
            webservice: PropTypes.string,
            is_url_up: PropTypes.bool,
        }),
        authentication: PropTypes.shape({
            username: PropTypes.string,
            password: PropTypes.string,
        }),
        labels: PropTypes.arrayOf(PropTypes.string),
        can_redeploy: PropTypes.bool,
    }).isRequired,
    jobInfo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        project_id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        auth_enabled: PropTypes.bool,
        cpu: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        ram: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        gpu: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        storage: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        appLinks: PropTypes.arrayOf(
            PropTypes.shape({
                isDefault: PropTypes.bool,
                isActive: PropTypes.bool,
                id: PropTypes.string,
                projectId: PropTypes.string,
            })
        ).isRequired,
    }).isRequired,
    isUrlUp: PropTypes.bool.isRequired,
    isApplicationProtected: PropTypes.bool.isRequired,
    isAnalyticsProtected: PropTypes.bool.isRequired,
    isAuth0Protected: PropTypes.bool.isRequired,
    isAuth0MissingBaseUrlSecret: PropTypes.bool.isRequired,
    setJobStatus: PropTypes.func.isRequired,
};

export default JobSummary;
