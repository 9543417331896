import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, Typography } from "@mui/material";
import { AccountContext } from "../../../../user/Account";
import PreDefineSecret from "./Auth0PreDefineSecret";
import {
    AUTH0_CLIENT_ID,
    AUTH0_CLIENT_SECRET,
    AUTH0_ISSUER_BASE_URL,
    AUTH0_SECRET,
    AUTH0_FEATURE_ID,
    hasAllAuth0Secrets,
    generateRandomString,
} from "./Auth0Utils";
import Auth0URLSetupInstruction from "./Auth0UrlSetupInstruction";
import StyledAuthenticationContainer from "../../../../../styles/features/applications/AuthenticationContainer.Styled";
import UpgradeAlert from "../../../../../components/Pricings/UpgradeAlert";
import { UserType } from "../../../../../models/enum.ts";

function Auth0Controller({
    isEnabled,
    onSecretsChange,
    onRemoveKey,
    secrets,
    projectUrl,
    setAuthCredentialsValid,
}) {
    const { canUserAccessComponent, userType } = useContext(AccountContext);
    const [userHasAccess, setUserHasAccess] = useState(false);

    useEffect(() => {
        setUserHasAccess(canUserAccessComponent(AUTH0_FEATURE_ID));
    }, [userType]);

    const getSecretValue = (key) => {
        const found = secrets.find((secret) => secret.key === key);
        return found?.value || "";
    };
    const hasPrevSecretValue = (key) => {
        const found = secrets.find((secret) => secret.key === key);
        return !!found?.previous;
    };

    const [isPrevIssuerUrl, setIsPrevIssuerUrl] = useState(false);
    const [isPrevClientId, setIsPrevClientId] = useState(false);
    const [isPrevClientSecret, setIsPrevClientSecret] = useState(false);
    const [isPrevSecret, setIsPrevSecret] = useState(false);

    const [hasValidationError, setHasValidationError] = useState(false);

    const [auth0IssuerUrl, setAuth0IssuerUrl] = useState("");
    const [auth0ClientId, setAuth0ClientId] = useState("");
    const [auth0ClientSecret, setAuth0ClientSecret] = useState("");
    const [auth0Secret, setAuth0Secret] = useState("");

    useEffect(() => {
        setIsPrevIssuerUrl(hasPrevSecretValue(AUTH0_ISSUER_BASE_URL));
        setIsPrevClientId(hasPrevSecretValue(AUTH0_CLIENT_ID));
        setIsPrevClientSecret(hasPrevSecretValue(AUTH0_CLIENT_SECRET));
        setIsPrevSecret(hasPrevSecretValue(AUTH0_SECRET));
        if (isEnabled) {
            const isSecretDefine = (secret) =>
                getSecretValue(secret) !== "" || hasPrevSecretValue(secret);
            let ok = hasAllAuth0Secrets(secrets);
            ok = ok && isSecretDefine(AUTH0_ISSUER_BASE_URL);
            ok = ok && isSecretDefine(AUTH0_CLIENT_ID);
            ok = ok && isSecretDefine(AUTH0_CLIENT_SECRET);
            ok = ok && isSecretDefine(AUTH0_SECRET);
            ok = ok && !hasValidationError;
            setAuthCredentialsValid(ok);
        } else {
            setAuthCredentialsValid(true);
        }
        setAuth0IssuerUrl(getSecretValue(AUTH0_ISSUER_BASE_URL));
        setAuth0ClientId(getSecretValue(AUTH0_CLIENT_ID));
        setAuth0ClientSecret(getSecretValue(AUTH0_CLIENT_SECRET));
        if (
            !getSecretValue(AUTH0_SECRET) &&
            !hasPrevSecretValue(AUTH0_SECRET) &&
            isEnabled
        ) {
            // Default value when component mount (if not already defined)
            const newSecret = generateRandomString();
            setAuth0Secret(newSecret);
            onSecretsChange({
                key: AUTH0_SECRET,
                value: newSecret,
            });
        } else {
            // Use current value
            setAuth0Secret(getSecretValue(AUTH0_SECRET));
        }
    }, [secrets, isEnabled]);

    if (!isEnabled) {
        return null;
    }

    if (!userHasAccess) {
        return (
            <UpgradeAlert
                featureDisplayName="Auth0 Integration"
                userType={UserType.TEAMS}
                featureId={AUTH0_FEATURE_ID}
            />
        );
    }

    return (
        <StyledAuthenticationContainer>
            <h2 style={{ marginTop: "0rem" }}> Auth0 Integration </h2>
            <div data-testid="auth0-set-env-var" className="container-auth0">
                <div className="left">
                    <h3> Add your Auth0 Credentials </h3>
                    <Typography>
                        These can be obtained from your Auth0 application page.
                        If you haven’t yet created an application, log into
                        Auth0 and create one.{" "}
                        <Link
                            href="https://docs.cloud.ploomber.io/en/latest/user-guide/password.html#deployment"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Documentation
                        </Link>
                    </Typography>
                </div>
                <div className="right">
                    <PreDefineSecret
                        index={3}
                        mkey={AUTH0_ISSUER_BASE_URL}
                        toolTipText="Correspond to your Auth0 Domain"
                        mvalue={auth0IssuerUrl}
                        isPrevValue={isPrevIssuerUrl}
                        onUpdate={(newValue) => {
                            setAuth0IssuerUrl(newValue);
                            onSecretsChange({
                                key: AUTH0_ISSUER_BASE_URL,
                                value: newValue,
                            });
                        }}
                        onRemoveKey={onRemoveKey}
                        setHasValidationError={setHasValidationError}
                    />
                    <PreDefineSecret
                        index={1}
                        mkey={AUTH0_CLIENT_ID}
                        toolTipText="Correspond to your Auth0 Client ID"
                        mvalue={auth0ClientId}
                        isPrevValue={isPrevClientId}
                        onUpdate={(newValue) => {
                            setAuth0ClientId(newValue);
                            onSecretsChange({
                                key: AUTH0_CLIENT_ID,
                                value: newValue,
                            });
                        }}
                        onRemoveKey={onRemoveKey}
                        setHasValidationError={() => {}}
                    />
                    <PreDefineSecret
                        index={2}
                        mkey={AUTH0_CLIENT_SECRET}
                        toolTipText="Correspond to your Auth0 Client Secret"
                        mvalue={auth0ClientSecret}
                        isPrevValue={isPrevClientSecret}
                        isPasswordInput
                        onUpdate={(newValue) => {
                            setAuth0ClientSecret(newValue);
                            onSecretsChange({
                                key: AUTH0_CLIENT_SECRET,
                                value: newValue,
                            });
                        }}
                        onRemoveKey={onRemoveKey}
                        setHasValidationError={() => {}}
                    />
                    <PreDefineSecret
                        index={4}
                        mkey={AUTH0_SECRET}
                        toolTipText="A long random string used to encrypt session cookies. We automatically generate this value for security"
                        mvalue={auth0Secret}
                        isPrevValue={isPrevSecret}
                        onUpdate={(newValue) => {
                            setAuth0Secret(newValue);
                            onSecretsChange({
                                key: AUTH0_SECRET,
                                value: newValue,
                            });
                        }}
                        onRemoveKey={onRemoveKey}
                        setHasValidationError={() => {}}
                        allowGenerateRandomValue
                    />
                </div>
            </div>
            <Auth0URLSetupInstruction projectUrl={projectUrl} />
        </StyledAuthenticationContainer>
    );
}

Auth0Controller.defaultProps = {
    projectUrl: "",
};

Auth0Controller.propTypes = {
    isEnabled: PropTypes.bool.isRequired,
    onSecretsChange: PropTypes.func.isRequired,
    onRemoveKey: PropTypes.func.isRequired,
    secrets: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            previous: PropTypes.bool,
            value: PropTypes.string,
        })
    ).isRequired,
    projectUrl: PropTypes.string,
    setAuthCredentialsValid: PropTypes.func.isRequired,
};

export default Auth0Controller;
