import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle, Box, Link, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CopyableContent from "../../../../../components/UI/CopyableContent";

function Auth0URLSetupInstruction({
    projectUrl,
    showCustomDomainWarning,
    CustomDomainWarningUrl,
}) {
    const getUrls = () => ({
        callback: `${projectUrl}/callback`,
        logout: `${projectUrl}/status`,
    });
    const warningUrl = CustomDomainWarningUrl || projectUrl;

    const [urls, setUrls] = useState(getUrls());
    const [isCustomDomain, setIsCustomDomain] = useState(false);

    const isPloomberDomain = (url) =>
        url.includes("ploomber.app") || url.includes("ploomberapp.io");

    useEffect(() => {
        setUrls(getUrls());
        setIsCustomDomain(!isPloomberDomain(projectUrl));
    }, [projectUrl]);

    return (
        <div className="container-auth0">
            <div className="left" data-testid="auth0-set-url-instruction">
                <h3>Setup your Auth0 callback and logout URL</h3>
                <Typography>
                    Add the callback and status URLs with your app URL.{" "}
                    <Link
                        href="https://docs.cloud.ploomber.io/en/latest/user-guide/password.html#set-callback-and-status-urls"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Documentation
                    </Link>
                </Typography>
            </div>

            <div className="right">
                {projectUrl ? (
                    <Box>
                        <h3>
                            Copy the following URL to Auth0 Callback URL input
                        </h3>
                        <CopyableContent
                            content={urls.callback}
                            testId="AUTH0-URL-CALLBACK"
                            ariaLabel="copy callback URL"
                        />

                        <h3>
                            Copy the following URL to Auth0 Logout URL input
                        </h3>
                        <CopyableContent
                            content={urls.logout}
                            testId="AUTH0-URL-LOGOUT"
                            ariaLabel="copy logout URL"
                        />

                        {showCustomDomainWarning && (
                            <Alert severity="info" icon={<InfoIcon />}>
                                <AlertTitle>
                                    {isCustomDomain
                                        ? "Custom Domain Configuration Required"
                                        : "Using a Custom Project Name ?"}
                                </AlertTitle>
                                <div>
                                    {isCustomDomain ? (
                                        <>
                                            <p>
                                                Your project is using a custom
                                                domain. Please complete these
                                                steps:
                                            </p>
                                            <ol>
                                                <li>
                                                    Add a secret named{" "}
                                                    <strong>
                                                        AUTH_BASE_URL
                                                    </strong>{" "}
                                                    with your domain:{" "}
                                                    <strong>
                                                        {projectUrl}
                                                    </strong>
                                                </li>
                                                <li>
                                                    Re-deploy your project for
                                                    the changes to take effect
                                                </li>
                                            </ol>
                                        </>
                                    ) : (
                                        <>
                                            <li>
                                                Add a new secret in the
                                                redeployment page named{" "}
                                                <strong>AUTH_BASE_URL</strong>{" "}
                                                with your domain
                                                <br />
                                                <strong
                                                    style={{
                                                        marginLeft: "1rem",
                                                    }}
                                                >
                                                    (e.g., {warningUrl}
                                                </strong>
                                                )
                                            </li>
                                            <li>
                                                Re-deploy your project for the
                                                changes to take effect
                                            </li>
                                        </>
                                    )}
                                </div>
                            </Alert>
                        )}
                    </Box>
                ) : (
                    <Box>
                        <h3>
                            Copy the following URLs to Auth0 Callback and Logout
                            URL inputs
                        </h3>
                        <Alert severity="info" icon={<InfoIcon />}>
                            Your project URL will be available after the first
                            deployment. Once deployed, go to the project
                            settings to get your callback and logout URLs for
                            Auth0 configuration
                        </Alert>
                    </Box>
                )}
            </div>
        </div>
    );
}

Auth0URLSetupInstruction.defaultProps = {
    projectUrl: "",
    showCustomDomainWarning: true,
    CustomDomainWarningUrl: "",
};

Auth0URLSetupInstruction.propTypes = {
    projectUrl: PropTypes.string,
    showCustomDomainWarning: PropTypes.bool,
    CustomDomainWarningUrl: PropTypes.string,
};

export default Auth0URLSetupInstruction;
