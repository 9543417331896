import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormControl } from "@mui/material";
import StyledAuthenticationContainer from "../../../../styles/features/applications/AuthenticationContainer.Styled";
import InputWithValidation from "../../../../components/UI/InputWithValidation";
import { AccountContext } from "../../../user/Account";
import UpgradeAlert from "../../../../components/Pricings/UpgradeAlert";
import { UserType } from "../../../../models/enum.ts";

const MIN_USERNAME_LEN = 3;
const MIN_PASSWORD_LEN = 4;
const FEATURE_ID = "password_authentication";

function PasswordController({
    isEnabled,
    onCredentialsUpdate,
    hadPreviousCredentials,
}) {
    const { canUserAccessComponent, userType } = useContext(AccountContext);
    const [isUsernameValid, setIsValidUsername] = useState(true);
    const [isPasswordValid, setIsValidPassword] = useState(true);

    const [newUsername, setNewUsername] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const [usernamePlaceholder, setUsernamePlaceholder] = useState("");
    const [passwordPlaceholder, setPasswordPlaceholder] = useState("");

    const [touched, setTouched] = useState(false);

    const [userHasAccess, setUserHasAccess] = useState(false);

    const [forceUpdate, setForceUpdate] = useState(0);

    const forceStateUpdate = () => setForceUpdate((prev) => prev + 1);

    const handleCredentialsChange = () => {
        setTouched(true);
    };

    useEffect(() => {
        const canAccess = canUserAccessComponent(FEATURE_ID);
        setUserHasAccess(canAccess);
    }, [userType]);

    useEffect(() => {
        setUsernamePlaceholder("Username");
        setPasswordPlaceholder("Password");
    }, [touched]);

    useEffect(() => {
        let carryOverAuth;

        if (touched) {
            carryOverAuth = false;
        } else if (isEnabled) {
            carryOverAuth = hadPreviousCredentials;
        } else {
            carryOverAuth = false;
        }

        onCredentialsUpdate({
            carryOverAuth,
            useAuth: isEnabled,
            areValid: carryOverAuth ? true : isUsernameValid && isPasswordValid,
            username: newUsername,
            password: newPassword,
        });
    }, [
        isUsernameValid,
        isPasswordValid,
        isEnabled,
        touched,
        forceUpdate,
        hadPreviousCredentials,
    ]);

    // new logic end
    const usernameValidator = (username) => {
        if (!touched) {
            return false;
        }

        let errorMessage;
        const spacesPattern = /\s/;

        if (username.length === 0) {
            errorMessage = `username is empty`;
        } else if (username.length > 0 && username.length < MIN_USERNAME_LEN) {
            errorMessage = `username is too short (The minimum allowed length is ${MIN_USERNAME_LEN} characters)`;
        } else if (spacesPattern.test(username)) {
            errorMessage = `username shouldn't include spaces`;
        }

        const isValid = !errorMessage;
        setIsValidUsername(isValid);
        forceStateUpdate();
        return errorMessage;
    };

    const passwordValidator = (password) => {
        if (!touched) {
            return false;
        }

        let errorMessage;
        const spacesPattern = /\s/;
        if (password.length === 0) {
            errorMessage = `password is empty`;
        } else if (password.length > 0 && password.length < MIN_PASSWORD_LEN) {
            errorMessage = `password is too short (The minimum allowed length is ${MIN_PASSWORD_LEN} characters)`;
        } else if (spacesPattern.test(password)) {
            errorMessage = `password shouldn't include spaces`;
        }

        const isValid = !errorMessage;
        setIsValidPassword(isValid);
        forceStateUpdate();

        return errorMessage;
    };

    useEffect(() => {
        if (hadPreviousCredentials) {
            setUsernamePlaceholder("●●●●●●");
            setPasswordPlaceholder("●●●●●●");
        }
    }, [hadPreviousCredentials, isEnabled]);

    if (!isEnabled) {
        return null;
    }

    if (!userHasAccess) {
        return (
            <UpgradeAlert
                featureDisplayName="Password Protection"
                userType={UserType.PRO}
                featureId={FEATURE_ID}
            />
        );
    }

    return (
        <StyledAuthenticationContainer>
            <div className="container-password">
                <FormControl sx={{ width: "50%" }} variant="outlined">
                    <InputWithValidation
                        id="username"
                        disabled={!isEnabled}
                        onChange={(e) => {
                            setNewUsername(e.target.value);
                            handleCredentialsChange();
                        }}
                        placeholder={usernamePlaceholder}
                        value={newUsername}
                        label="Username"
                        validator={usernameValidator}
                    />
                </FormControl>
                <FormControl sx={{ width: "50%" }} variant="outlined">
                    <InputWithValidation
                        id="password"
                        type="password"
                        disabled={!isEnabled}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                            handleCredentialsChange();
                        }}
                        placeholder={passwordPlaceholder}
                        value={newPassword}
                        label="Password"
                        validator={passwordValidator}
                    />
                </FormControl>
            </div>
        </StyledAuthenticationContainer>
    );
}

PasswordController.propTypes = {
    isEnabled: PropTypes.bool.isRequired,
    onCredentialsUpdate: PropTypes.func.isRequired,
    hadPreviousCredentials: PropTypes.bool.isRequired,
};

export default PasswordController;
