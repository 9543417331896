import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PropTypes from "prop-types";
import { Box, IconButton } from "@mui/material";

function CopyableContent({
    content,
    backgroundColor = "grey.100",
    padding = 1,
    borderRadius = 1,
    marginBottom = 2,
    ariaLabel = "copy content",
    testId = "copyContent",
    renderContent = (c) => <code style={{ flex: 1 }}>{c}</code>,
}) {
    const handleCopy = () => {
        navigator.clipboard.writeText(content);
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                bgcolor: backgroundColor,
                p: padding,
                borderRadius,
                mb: marginBottom,
            }}
            data-testId={testId}
        >
            {renderContent(content)}
            <IconButton
                size="small"
                onClick={handleCopy}
                aria-label={ariaLabel}
            >
                <ContentCopyIcon fontSize="small" />
            </IconButton>
        </Box>
    );
}

CopyableContent.propTypes = {
    // Required props
    content: PropTypes.string.isRequired,

    // Optional props
    backgroundColor: PropTypes.string,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ariaLabel: PropTypes.string,
    renderContent: PropTypes.func,
    testId: PropTypes.string,
};

CopyableContent.defaultProps = {
    backgroundColor: "grey.100",
    padding: 1,
    borderRadius: 1,
    marginBottom: 2,
    ariaLabel: "copy content",
    testId: "copyContent",
    renderContent: (content) => <code style={{ flex: 1 }}>{content}</code>,
};

export default CopyableContent;
