import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import JobSummary from "./JobSummary";
import JobStepper from "./JobStepper";

import StyledJobStatus from "../../styles/features/applications/JobStatus.Styled";
import { withApplicationProvider } from "./ApplicationProvider";
import { checkIfEKSDeployment } from "../../utils/utils.ts";
import {
    AUTH0_TEMPLATE_NAME,
    hasCustomDomainAuth0Secret,
} from "./controllers/AuthenticationController/Auth0/Auth0Utils";

function JobStatusViewer({
    status,
    projectId,
    project,
    jobInfo,
    job,
    isUrlUp,
    jobId,
    setJobStatus,
}) {
    const isApplicationProtected = "isAuth" in job;
    const isAnalyticsProtected = "isAnalyticsAuth" in job;

    const lastJob = project?.jobs?.at(-1);
    const secrets = project?.secrets?.map((secret) => ({ key: secret })) ?? [];
    const hasMultipleAppLinks = (jobInfo?.appLinks?.length ?? 0) > 1;
    const isAuth0Protected = lastJob?.template === AUTH0_TEMPLATE_NAME;
    const isAuth0MissingBaseUrlSecret =
        isAuth0Protected &&
        !hasCustomDomainAuth0Secret(secrets) &&
        hasMultipleAppLinks;

    return (
        <StyledJobStatus className="JobStatus">
            <JobSummary
                status={status}
                job={job}
                jobInfo={jobInfo}
                isUrlUp={isUrlUp}
                isAuth0Protected={isAuth0Protected}
                isAuth0MissingBaseUrlSecret={isAuth0MissingBaseUrlSecret}
                isApplicationProtected={isApplicationProtected}
                isAnalyticsProtected={isAnalyticsProtected}
                setJobStatus={setJobStatus}
            />

            {!checkIfEKSDeployment(jobInfo) && (
                <Box className="JobStepperContainer">
                    <JobStepper job={job} />
                </Box>
            )}
        </StyledJobStatus>
    );
}

JobStatusViewer.propTypes = {
    status: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    project: PropTypes.shape({
        jobs: PropTypes.arrayOf(
            PropTypes.shape({
                cpu: PropTypes.string,
                ram: PropTypes.string,
                gpu: PropTypes.string,
                storage: PropTypes.string,
            })
        ),
        type: PropTypes.string.isRequired,
        secrets: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    jobInfo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        project_id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        appLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    job: PropTypes.shape({
        projectName: PropTypes.string,
        summary: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
        status: PropTypes.string.isRequired,
        resources: PropTypes.shape({
            webservice: PropTypes.string,
            is_url_up: PropTypes.bool,
        }),
        authentication: PropTypes.shape({
            username: PropTypes.string,
            password: PropTypes.string,
        }),
        labels: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    isUrlUp: PropTypes.bool.isRequired,
    jobId: PropTypes.string.isRequired,
    setJobStatus: PropTypes.func.isRequired,
};

JobStatusViewer.defaultProps = {
    project: undefined,
};

export default withApplicationProvider(JobStatusViewer);
